export const hidfLyon = {
  popup: {
    src: "https://extracadabra-paris.typeform.com/to/gqVHDtie",
  },
  partnersSlider: {
    title1: "Plus de",
    title2: "10 000 entreprises",
    title3: "nous font confiance !",
    subtitle: "Restaurants, Cafés, Bars…",
  },
  verbatims: {
    title: "Nos extras en parlent mieux que nous !",
  },
  faqs: {
    title: "FAQ",
    list: [
      {
        question:
          "Quels sont les avantages à devenir auto-entrepreneur chez Extracadabra ?",
        answer:
          "En tant qu’auto-entrepreneur, tu recevras l’intégralité des missions proposées sur l’application.\n\nTu es ton propre patron et tu peux ainsi choisir de travailler au rythme de tes envies.\n\nReçois ta paie tous les 15 jours par virement bancaire et les factures par email.",
      },
      {
        question: "Suis-je assuré durant ma mission ?",
        answer:
          "Bien sûr ! Extracadabra t’assure gratuitement auprès de son partenaire AXA.\n\n<u>Assurance Responsabilité civile professionnelle :</u>\nL’assurance AXA te couvre en cas de dommages corporels, matériels et immatériels causés à tes clients ou à toutes autres personnes dans le cadre d’une prestation effectuée avec nous.\n\n<u>Prévoyance santé :</u>\nCe contrat te couvre en cas d’arrêt de travail. Quel que soit l’événement, l’objectif de la prévoyance est d’assurer le maintien de tes revenus. grâce à notre partenaire, tu es automatiquement couvert pendant toute la durée de ta mission, mais aussi sur le trajet aller et retour (dans la limite de 60 minutes pour chaque trajet).",
      },
      {
        question: "Quelles sont les étapes pour créer mon compte ?",
        answer:
          "Tout d’abord crée ton profil sur notre application ou en ligne.\n\n<u>Renseigne bien les informations suivantes :</u>\n- Ta présentation personnelle\n- Tes expériences professionnelles ainsi que leurs références\n- Tes compétences, tes formations et tes langues parlées\n- Tes critères de recherche\n- Tes disponibilités\n\nCela te permettra d’avoir un profil validé rapidement.\nEn complétant au maximum ton profil, tu pourras postuler aux offres à long terme (CDI et Saisonnier) ainsi qu'aux missions ponctuelles.\nPar la suite, si tu souhaites effectuer des missions en tant qu’auto-entrepreneur, tu devras ajouter les documents administratifs demandés.",
      },
    ],
  },
};
