export const landings = {
  metro: {
    logo: "https://static-cadabra.s3.amazonaws.com/www/landing/metro/logo-metro.png",
    mentions:
      "<ul><li>*Offre valables pour toute entreprise n'ayant jamais été client Extracadabra ou n'ayant pas été client Extracadabra depuis plus de deux ans</li><li>*Offre valable pour toute première mission extra de minimum 20 heures</li></ul>",
    howManyEmployeeOptions: [
      "Moins de 5",
      "Entre 5 et 10",
      "Entre 10 et 20",
      "Plus de 20",
    ],
    segmentationOptions: [
      "Restauration traditionnelle",
      "Restauration rapide",
      "Boulangerie / Pâtisserie",
      "Café / Bar / Pub",
      "Traiteur / Evènementiel",
      "Hôtel",
      "Autres",
    ],
  },
  jo: {
    slug: "jo",
    webhookUrl: "https://hooks.zapier.com/hooks/catch/7999757/3xc0r5o/",
    form: {
      isAe: ["Oui", "Non"],
      qualifications: [
        {
          optgroup: "Salle",
          names: [
            "Runner",
            "Serveur",
            "Chef de rang",
            "Maître d’hôtel",
            "Responsable de salle",
            "Sommelier",
          ],
        },
        {
          optgroup: "Cuisine",
          names: [
            "Plongeur",
            "Commis de cuisine",
            "Chef de partie",
            "Chef de Cuisine",
            "Employé polyvalent",
            "Pâtissier",
          ],
        },
        {
          optgroup: "Bar",
          names: ["Limonadier", "Barista", "Barman", "Mixologue"],
        },
        {
          optgroup: "Accueil / Logistique",
          names: ["Manutentionnaire", "Road", "Hôte.sses d’accueil", "Vestiaire"],
        },
        {
          optgroup: "Autres",
          names: ["Autres"],
        },
      ],
      successMessage:
        "Merci pour ton inscription.<br />Nous te contacterons très rapidement.",
    },
    jobsCard: [
      {
        id: "1630543840256254",
        picture: "https://static-cadabra.s3.amazonaws.com/www/landing/jo/runner.jpg",
        city: "Paris",
        postal_code: "75004",
        contract_type: "auto_e",
        publication_date: "2024-03-29 09:39:44",
        start_date: "2024-08-05 18:00:00",
        qualification: "Runner",
        duration: "6 jours",
        is_jo: true,
        salary: {
          amount: "18",
          forecast: "108",
        },
        etab: {
          category: "Restaurant traditionnel",
        },
        typical_schedule: {
          schedule: "18h00-23h00",
        },
        url: "runner",
      },
      {
        id: "1630543840256254",
        picture:
          "https://static-cadabra.s3.amazonaws.com/www/landing/jo/maitre-dhotel.jpg",
        city: "Le Bourget",
        postal_code: "93350",
        contract_type: "interim",
        publication_date: "2024-03-29 11:39:44",
        start_date: "2024-07-29 18:00:00",
        qualification: "Maitre d’hôtel",
        duration: "5 jours",
        is_jo: true,
        salary: {
          amount: "18",
          forecast: "720",
        },
        etab: {
          category: "Traiteur JO - Le Bourget",
        },
        typical_schedule: {
          schedule: "16h00-0h00",
        },
        url: "maitre-d-hotel",
      },
      {
        id: "1630543840256254",
        picture: "https://static-cadabra.s3.amazonaws.com/www/landing/jo/barman.jpg",
        city: "Paris",
        postal_code: "75005",
        contract_type: "auto_e",
        publication_date: "2024-08-11 09:39:44",
        start_date: "2024-06-24 11:00:00",
        qualification: "Barman",
        duration: "6 jours",
        is_jo: true,
        salary: {
          amount: "21,60",
          forecast: "648",
        },
        etab: {
          category: "Restaurant chic",
        },
        typical_schedule: {
          schedule: "11h00-16h00",
        },
        url: "poste-barman",
      },
      {
        id: "1630543840256254",
        picture:
          "https://static-cadabra.s3.amazonaws.com/www/landing/jo/chef-de-partie.jpg",
        city: "Paris",
        postal_code: "75008",
        contract_type: "interim",
        publication_date: "2024-08-11 09:39:44",
        start_date: "2024-08-11 18:00:00",
        qualification: "Chef de partie",
        duration: "21 jours",
        is_jo: true,
        salary: {
          amount: "18",
          forecast: "3024",
        },
        etab: {
          category: "Traiteur JO - Invalides",
        },
        typical_schedule: {
          schedule: "08h00-16h00",
        },
        url: "chef-de-partie",
      },
      {
        id: "1630543840256254",
        picture:
          "https://static-cadabra.s3.amazonaws.com/www/landing/jo/chef-de-rang.jpg",
        city: "Paris",
        postal_code: "75005",
        contract_type: "auto_e",
        is_jo: true,
        publication_date: "2024-03-29 09:39:44",
        start_date: "2024-07-21 18:00:00",
        qualification: "Chef de rang",
        duration: "21 jours",
        salary: {
          amount: "23,40",
          forecast: "5405.50",
        },
        etab: {
          category: "Restaurant traditionnel",
        },
        typical_schedule: {
          schedule: "18h00-0h00",
        },
        url: "chef-de-rang",
      },

      {
        id: "1630543840256254",
        picture: "https://static-cadabra.s3.amazonaws.com/www/landing/jo/serveur.jpg",
        city: "Paris",
        postal_code: "75001",
        contract_type: "auto_e",
        publication_date: "2024-03-29 09:39:44",
        start_date: "2024-07-24 18:00:00",
        qualification: "Serveur",
        duration: "4 jours",
        is_jo: true,
        salary: {
          amount: "20,40",
          forecast: "897,6",
        },
        etab: {
          category: "Restaurant Italien",
        },
        typical_schedule: {
          schedule: "12h00-23h00",
        },
        url: "serveur",
      },
      {
        id: "1630543840256254",
        picture:
          "https://static-cadabra.s3.amazonaws.com/www/landing/jo/commis-de-cuisine.jpg",
        city: "Paris",
        postal_code: "75008",
        contract_type: "auto_e",
        is_jo: true,
        publication_date: "2024-03-29 11:39:44",
        start_date: "2024-07-27 18:00:00",
        qualification: "Commis de cuisine",
        duration: "1 jour",
        salary: {
          amount: "17.40",
          forecast: "139,20",
        },
        etab: {
          category: "Hotel *****",
        },
        typical_schedule: {
          schedule: "06h00-14h00",
        },
        url: "recherche?q=13",
      },
    ],
  },
  lpEtabLyon: {
    slug: "lp-etab-lyon",
    webhookUrl: "https://hooks.zapier.com/hooks/catch/7999757/2b6s4m4/",
    form: {
      numberOfEtab: ["Entre 1 et 3", "3 et 6", "Plus de 6"],
      successMessage:
        "Merci pour votre demande.<br />Nous vous contacterons très rapidement.",
    },
    faqs: {
      title: "Recruteurs : Vos questions, nos réponses",
      list: [
        {
          question:
            "A quel point Extracadabra gère t-il les demandes de renfort en urgence ? ",
          answer:
            "Notre système de matching et votre chargé de compte peuvent faire des miracles ! Tout dépend du nombre de renfort dont vous avez besoin. Généralement 48h avant le début de la mission, vous n’aurez pas de mal à trouver des candidats pour se positionner sur votre demande. Si votre besoin est la veille pour le lendemain il est plus raisonnable de doubler votre demande par un message à votre chargé de compte. Vous pouvez également augmenter le tarif horaire de votre demande afin de vous assurer de trouver des candidats, même dans l’urgence !",
        },
        {
          question:
            "Aurais-je un interlocuteur dédié pour suivre mes demandes de prestations Extra ?",
          answer:
            "Chez Extracadabra, nous avons le support client le plus réactif ! Votre contact dédié sur la ville de Lyon vous accompagnera si besoin dans la réalisation et le suivi de vos demandes… Nous restons joignables 6 jours / 7 pour gérer au mieux tous vos besoins de renfort en urgence.",
        },
        {
          question:
            "Comment suis-je assuré que le candidat qui postule à mon offre est qualifié ?",
          answer:
            "Suite à l’inscription du candidat, nous appliquons un process pour nous assurer de la véracité des informations fournises. Nous demandons par exemple une recommandation à nos candidats et nous appelons systématiquement les anciens managers pour vérifier les expériences renseignées. Vous aurez accès au CV de chacun des Extras présents sur la plateforme et les notations reçues lors de leur précédentes expériences.",
        },
      ],
    },
  },
  interim: {
    slug: "interim",
    faqs: {
      title: "FAQ",
      list: [
        {
          question: "C’est quoi l’intérim ?",
          answer:
            "Il s'agit d'un contrat de travail à durée limitée qui permet à un candidat de pourvoir une mission précise et ponctuelle au sein d'une entreprise qui a besoin de main d'œuvre temporaire/de renfort.",
        },
        {
          question: "Qui peut faire de l’intérim en restauration ?",
          answer:
            "<strong>Toute personne à la recherche de missions ponctuelles</strong><ul><li>Si vous souhaitez trouver un job intérimaire pour compléter vos revenus actuels, c’est tout à fait possible même si vous travaillez déjà pour une entreprise en CDD ou CDI. Il vous faut seulement respecter quelques règles.</li><li>L’intérim pour les travailleurs étrangers est possible à condition de nous présenter un titre de séjour en cours de validité justifiant d’une situation régulière en France.</li><li>Si vous avez un titre de séjour avec la mention commerçant, ce dernier n’autorise pas à faire une activité salariale et donc n’autorise pas à faire de l’intérim.</li><li>Si vous êtes un étudiant étranger, vous pouvez faire de l’intérim, à condition de ne pas dépasser les 964 heures par an.</li><li>Si vous êtes un étudiant Algérien et que vous souhaitez faire de l’intérim en France, il vous faut une autorisation de travail.</li></ul>",
        },
        {
          question: "Les avantages de l’intérim",
          answer:
            "<ul><li>Vous avez les <strong>mêmes droits</strong> que les autre salariés de l’entreprise que vous intégrez en tant qu’intérimaire.</li><li>L'intérim vous permet d'intégrer une <strong>grande diversité d'entreprises</strong> et donc de diversifier vos expériences dans différents secteurs</li><li>L’intérim vous permet d’avoir une <strong>flexibilité professionnelle</strong>.</li><li>Des <strong>indemnités de fin de mission</strong> équivalente à 10% de la rémunération brute totale</li><li>Des <strong>indemnités compensatrice de congés payés</strong> égal à 10% de la rémunération brute totale.</li></ul>",
        },

        {
          question: "Comment devenir intérimaire chez Extracadabra ?",
          answer:
            "<strong>Etape 1 : </strong> Il faut créer un compte Extracadabra<br /><strong>Etape 2 : </strong> Remplir le lien d’inscription envoyé par nos équipes.<br />Voici la liste des documents qui vous serons demandés pour créer votre dossier intérimaire:<br /></br /><ul><li>Pièce d’identité recto/verso</li><li>Titre de séjour valide recto/verso ou récepissé + titre de séjour expiré</li><li>Carte vital</li><li>Justificatif de domicile ou attestation d’hebergement + CNI de l’hebergeur</li><li>RIB</li><li>Photo récente</li></ul>",
        },
        {
          question: "Mon compte intérimaire Armado",
          answer:
            "Armado est votre espace intérimaire, un logiciel de dématérialisation qui vous permettra de:<br /><br /><ul><li>Visualiser et signer vos contrats de travail</li><li>Visualiser et télécharger vos fiches de paies</li><li>Faire des demandes d’acomptes.</li></ul>",
        },
        {
          question: "Qui est Recrutop?",
          answer:
            "Recrutop est notre agence d’intérim partenaire depuis 2022. Elle est en charge de la gestion administrative liées à vos missions Extracadabra: Paies et contrats. Cependant, Extracadabra reste ton contact principal pour toute question.",
        },
        {
          question: "Quand suis-je rémunéré pour une mission en intérim ?",
          answer:
            "Le paiement se fait le 10 du mois qui suit la mission.<br /><br /><strong>Exemples:</strong><br />J’ai travaillé le 02 septembre; je suis payé le 10 octobre.<br />J’ai travaillé le 26 septembre; je suis payé le 10 octobre.<br />NB: Possibilité de faire une demande d’acompte via votre compte Armado",
        },
        {
          question:
            "Quels sont les taux horaires pratiqués en interim en restauration ? ",
          answer:
            "Voici quelques exemples de taux horaire pratiqués en interim restauration à Paris : <br /><br />Commis de cuisine à partir de 12€/heure<br />Chef de partie à partir de 16€/heure<br />Chef de cuisine à partir de 18€/heure<br />Runner à partir de 14€/heure<br />Chef de rang à partir de 15€/heure<br />Maître d’hôtel à partir de 16€/heure",
        },
      ],
    },
  },
};
