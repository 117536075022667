export const needHelp = {
  etab: {
    popup: {
      src: "https://extracadabra-paris.typeform.com/to/UIKX0skF",
    },
    external: true,
  },
  candidates: {
    url: "#url_typeform_candidates",
    external: true,
  },
};
