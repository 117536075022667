import { $fetch } from "ofetch";
import type { FetchOptions } from "ofetch";

import ArticleModule from "~/repository/modules/articles";
import FaqsModule from "~/repository/modules/faq/faqs";
import FaqGroupsModule from "~/repository/modules/faq/groups";
import FaqTypesModule from "~/repository/modules/faq/types";
import LatestArticlesModule from "~/repository/modules/latestArticles";
import SliderArticlesModule from "~/repository/modules/sliderArticles";
import TagsModule from "~/repository/modules/tags";
import PopupDataModule from "~/repository/modules/popupData/register";
import LeadGenDataModule from "~/repository/modules/leadGenData/register";
import PopupsModule from "~/repository/modules/popups";
import PressesModule from "~/repository/modules/presses";
import PartnersModule from "~/repository/modules/partners";
import VerbatimsModule from "~/repository/modules/verbatims";
import JobOffersModule from "~/repository/modules/jobOffers";

interface ApiInstance {
  articles: ArticleModule;
  tags: TagsModule;
  latestArticles: LatestArticlesModule;
  sliderArticles: SliderArticlesModule;
  faqTypes: FaqTypesModule;
  faqGroups: FaqGroupsModule;
  faqs: FaqsModule;
  popupData: PopupDataModule;
  leadGenData: LeadGenDataModule;
  popups: PopupsModule;
  presses: PressesModule;
  partners: PartnersModule;
  verbatims: VerbatimsModule;
  jobs: JobOffersModule;
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const headers = new Headers();

  if (process.env.NODE_ENV === "development") {
    headers.append("ngrok-skip-browser-warning", "69420");
  }

  const fetchStrapiOptions: FetchOptions = {
    baseURL: config.public.baseURL,
    headers,
  };
  const fetchJobsOptions: FetchOptions = {
    baseURL: config.public.apiURL,
    headers,
  };

  const apiStrapiFetcher = $fetch.create(fetchStrapiOptions);
  const apiJobsFetcher = $fetch.create(fetchJobsOptions);

  const modules: ApiInstance = {
    articles: new ArticleModule(apiStrapiFetcher),
    tags: new TagsModule(apiStrapiFetcher),
    latestArticles: new LatestArticlesModule(apiStrapiFetcher),
    sliderArticles: new SliderArticlesModule(apiStrapiFetcher),
    faqs: new FaqsModule(apiStrapiFetcher),
    faqTypes: new FaqTypesModule(apiStrapiFetcher),
    faqGroups: new FaqGroupsModule(apiStrapiFetcher),
    popupData: new PopupDataModule(apiStrapiFetcher),
    leadGenData: new LeadGenDataModule(apiStrapiFetcher),
    popups: new PopupsModule(apiStrapiFetcher),
    presses: new PressesModule(apiStrapiFetcher),
    partners: new PartnersModule(apiStrapiFetcher),
    verbatims: new VerbatimsModule(apiStrapiFetcher),
    jobs: new JobOffersModule(apiJobsFetcher),
  };

  return {
    provide: {
      api: modules,
    },
  };
});
