import revive_payload_client_YTGlmQWWT5 from "/var/www/showcase/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/var/www/showcase/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/var/www/showcase/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_SiLaiDf04L from "/var/www/showcase/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_VTarsGAzgb from "/var/www/showcase/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_xKuw1km5sV from "/var/www/showcase/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/showcase/packages/client/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/var/www/showcase/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_Jg57Vw3Til from "/var/www/showcase/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import i18n_kYI7ffAomL from "/var/www/showcase/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import plugin_iusAUtqzOR from "/var/www/showcase/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import composition_N1rIkEnC9Q from "/var/www/showcase/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_dOTnCc6TUQ from "/var/www/showcase/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import view_transitions_client_HXqymDRHmq from "/var/www/showcase/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/var/www/showcase/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/var/www/showcase/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import api_GFfDXud5Cr from "/var/www/showcase/packages/client/plugins/api.ts";
import checkTypeform_client_lCl7jhP3Wu from "/var/www/showcase/packages/client/plugins/checkTypeform.client.ts";
import datalayer_G9Aj5JlukA from "/var/www/showcase/packages/client/plugins/datalayer.ts";
import gtm_cXsUZobmSF from "/var/www/showcase/packages/client/plugins/gtm.ts";
import nuxtLink_fMHXP99Xgu from "/var/www/showcase/packages/client/plugins/nuxtLink.ts";
import posthog_client_fUucxKWhZE from "/var/www/showcase/packages/client/plugins/posthog.client.ts";
import posthog_ulvgx15Dgc from "/var/www/showcase/packages/client/plugins/posthog.ts";
import sentry_client_shVUlIjFLk from "/var/www/showcase/packages/client/plugins/sentry.client.ts";
import tolk_GrcDqt8RHk from "/var/www/showcase/packages/client/plugins/tolk.ts";
import utmTracker_Lcbcjid4BF from "/var/www/showcase/packages/client/plugins/utmTracker.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_SiLaiDf04L,
  payload_client_VTarsGAzgb,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  nuxt_plugin_Jg57Vw3Til,
  i18n_kYI7ffAomL,
  plugin_iusAUtqzOR,
  composition_N1rIkEnC9Q,
  i18n_dOTnCc6TUQ,
  view_transitions_client_HXqymDRHmq,
  chunk_reload_client_SeG0EjL5Ec,
  check_outdated_build_client_LIYcCMJD18,
  api_GFfDXud5Cr,
  checkTypeform_client_lCl7jhP3Wu,
  datalayer_G9Aj5JlukA,
  gtm_cXsUZobmSF,
  nuxtLink_fMHXP99Xgu,
  posthog_client_fUucxKWhZE,
  posthog_ulvgx15Dgc,
  sentry_client_shVUlIjFLk,
  tolk_GrcDqt8RHk,
  utmTracker_Lcbcjid4BF
]